import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section/Section';
import Hgroup from '../components/UI/Hgroup/Hgroup';
import { themes } from "../context/ThemeContext";

const NotFoundPage = () => (
  <Layout>
    <Section Tag="div" theme={themes.LIGHT}>
      <Hgroup lead="404" title="Not found" isPrimary/>
      <p>You just hit a page that doesn&#39;t exist... the sadness.</p>
    </Section>
  </Layout>
)

export default NotFoundPage
